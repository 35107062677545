import { Link } from "react-router-dom";
import { PageTitle } from "../../components/PageTitle";
import logo from "../../assets/lera.png";
import styles from "./ActivateAccountNotifier.module.css";

export const ActivateAccountNotifierPage = () => {
  return (
    <PageTitle title="Activate Account Notification - Lera API">
      <div className={styles.activate_container}>
        <header>
          <Link to="/">
            <img src={logo} alt="Lera API" className={styles.logo} />
          </Link>
        </header>
        <main>
          <div className={styles.activate_intro}>
            <h1 className={styles.activate_text}>Activate Your Account</h1>
          </div>
          <div className={styles.activate_box}>
            <p>
              A verification link has been sent to the email used to sign up.
              Please click on the link to activate your account.
            </p>
          </div>
        </main>
      </div>
    </PageTitle>
  );
};
