import { ReactNode, useEffect, useRef } from "react";
import "./PageTitle.module.css";

interface PageTitleProps {
  title: string;
  children: ReactNode;
}

export const PageTitle = ({ title, children }: PageTitleProps) => {
  const pageRef = useRef<HTMLDivElement>(null!);

  useEffect(() => {
    document.title = title;
    pageRef.current.focus();

    return () => {
      document.title = "";
    };
  }, []);

  return (
    <div ref={pageRef} tabIndex={-1} data-page-title>
      {children}
      <div className="visually-hidden" role="alert">
        {title}
      </div>
    </div>
  );
};
