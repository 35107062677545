import { useEffect, useState } from "react";
import { PlanType } from "../../utils/general";
import { GET_PLAN_DETAILS } from "../../api/endpoints";
import { useCustomAxios } from "../../api/axios";
import { PageTitle } from "../../components/PageTitle";
import { AuthLayout } from "../../components/AuthLayout";
import { SubmitButton } from "../../components/SubmitButton";
import styles from "./TopupPlans.module.css";

export const TopupPlansPage = () => {
  const [plans, setPlans] = useState<PlanType[]>([]);
  const { makeRequest } = useCustomAxios();

  // TODO: implement after Stripe integration
  const handleTopup = () => {};

  useEffect(() => {
    makeRequest(
      [
        {
          url: GET_PLAN_DETAILS,
          method: "get",
          params: {
            plan_type: "top-up",
          },
        },
      ],
      (responseDataArr) => {
        const [plansData] = responseDataArr;
        setPlans(plansData);
      },
      (error) => console.error(error) // TODO: refactor error handling
    );
  }, []);

  return (
    <PageTitle title="Pay As You Go - Lera API">
      <AuthLayout>
        <div className={styles.topup_container}>
          <h1>Pay as you go</h1>
          <form onSubmit={handleTopup} noValidate>
            <p>
              To pay online, please select the number of credits you wish to
              purchase:
            </p>
            <div className={styles.topup}>
              <label htmlFor="topup-options">Select Amount</label>
              <div className={styles.topup_options}>
                <select id="topup-options">
                  {plans.map((plan) => (
                    <option key={plan.plan_id}>
                      {`$${plan.plan_cost.$numberDecimal} for ${plan.scan_limit} scans`}
                    </option>
                  ))}
                </select>
              </div>
              <SubmitButton label="Proceed To Payment" iconRight="ArrowRight" />
            </div>
          </form>
        </div>
      </AuthLayout>
    </PageTitle>
  );
};
