import { HomePage } from "../pages/home";
import { Signup } from "../pages/signup";
import { ActivateAccountNotifierPage } from "../pages/activate-account-notifier";
import { ActivateAccountPage } from "../pages/activate-account";
import { ForgotPassword } from "../pages/forgot-password";
import { ResetPassword } from "../pages/reset-password";
import { ResetPasswordLinkNotifier } from "../pages/reset-password-link-notifier";
import { UpdatePassword } from "../pages/update-password";
import { ResendVerificationLink } from "../pages/resend-verification-link";
import { ExpiredVerificationLink } from "../pages/expired-verification-link"
import { ExpiredPasswordResetLink } from "../pages/expired-password-reset"


export const unauthenticatedUserRoutes = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/activate-account",
    children: [
      {
        path: "notification",
        element: <ActivateAccountNotifierPage />,
      },
      {
        path: ":token",
        element: <ActivateAccountPage />,
      },
    ],
  },
  {
    path: "/resend-verification-link",
    element: <ResendVerificationLink />,
  },
  {
    path: "/expired-verification-link",
    element: <ExpiredVerificationLink />,
  },
  {
    path: "/expired-password-reset-link",
    element: <ExpiredPasswordResetLink />, 
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    children: [
      {
        index: true,
        element: <ResetPassword />,
      },
      {
        path: "link-notification",
        element: <ResetPasswordLinkNotifier />,
      },
      {
        path: ":token",
        element: <UpdatePassword />,
      },
    ],
  },
];
