import { PageTitle } from "../../components/PageTitle";
import { AuthLayout } from "../../components/AuthLayout";
import { Tab } from "../../components/Tab";
import { UserInfo } from "./UserInfo";
import { UpdatePassword } from "./UpdatePassword";
import styles from "./Profile.module.css";

export const ProfilePage = () => {
  const profileTabList = [
    {
      header: "General",
      content: (
        <UserInfo
          userInfoStylesClass={styles.profile_content}
          textFieldStylesClass={styles.input_box}
          ctaStylesClass={styles.cta}
        />
      ),
    },
    {
      header: "Change Password",
      content: (
        <UpdatePassword
          passwordFormStylesClass={styles.profile_content}
          passwordFieldStylesClass={styles.input_box}
          passwordCtaStylesClass={styles.cta}
        />
      ),
    },
  ];

  return (
    <PageTitle title="Profile - Lera API">
      <AuthLayout pageH1="Profile">
        <div className={styles.profile_container}>
          <Tab tabList={profileTabList} />
        </div>
      </AuthLayout>
    </PageTitle>
  );
};
