import {
  useId,
  useState,
  useRef,
  useEffect,
  FormEvent,
  ChangeEvent,
} from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { UPDATE_PASSWORD_ENDPOINT } from "../../api/endpoints";
import { useCustomAxios } from "../../api/axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { PageTitle } from "../../components/PageTitle";
import { PasswordField } from "../../components/PasswordField";
import { SubmitButton } from "../../components/SubmitButton";
import { ErrorSummary } from "../../components/ErrorSummary";
import logo from "../../assets/lera.png";
import styles from "./styles/UpdatePassword.module.css";

interface UpdatePasswordDataType {
  password: string;
  password2: string;
}

export const UpdatePassword = () => {
  const id = useId();
  const updatePasswordDataIds = useRef<UpdatePasswordDataType>({
    password: `password-${id}`,
    password2: `password2-${id}`,
  });
  const [formData, setFormData] = useState<UpdatePasswordDataType>({
    password: "",
    password2: "",
  });
  const [formErrors, setFormErrors] = useState({} as UpdatePasswordDataType);
  const [formErrorsCount, setFormErrorsCount] = useState(0);
  const errorsRef = useRef<HTMLDivElement>(null);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const validateFormData = () => {
    let validationErrors = {} as UpdatePasswordDataType;
    let errorsCount = 0;
    if (formData.password === "") {
      validationErrors.password = "You must enter a new password";
      errorsCount++;
    }
    if (formData.password2 === "") {
      validationErrors.password2 = "You must re-enter your new password";
      errorsCount++;
    } else if (formData.password2 !== formData.password) {
      validationErrors.password2 = "Your passwords do not match";
      errorsCount++;
    }
    return { errorsCount, validationErrors };
  };

  const navigate = useNavigate();
  const { token } = useParams();
  const { isLoading, makeRequest } = useCustomAxios();
  const handlePasswordUpdate = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { errorsCount, validationErrors } = validateFormData();
    if (errorsCount > 0) {
      setFormErrors(validationErrors);
      setFormErrorsCount(errorsCount);
    } else {
      makeRequest(
        [
          {
            url: UPDATE_PASSWORD_ENDPOINT,
            method: "post",
            data: {
              password: formData.password,
              token: decodeURIComponent(token!),
            },
          },
        ],
        () => navigate("/login"),
        (error) => console.error(error)
      );
    }
  };

  const handleCancelPasswordUpdate = () => {
    setFormData({
      password: "",
      password2: "",
    });
    setFormErrors({} as UpdatePasswordDataType);
    setFormErrorsCount(0);
  };

  useEffect(() => {
    if (formErrorsCount > 0) {
      errorsRef.current?.focus();
    }
  }, [formErrorsCount]);

  return (
    <PageTitle title="Create New Password - Lera API">
      <div className={styles.update_password_container}>
        <header className={styles.update_password_header}>
          <Link to="/">
            <img src={logo} alt="Lera API" />
          </Link>
        </header>
        <main>
          <h1 className={styles.update_password_h1}>
            Create your new password
          </h1>
          <div className={styles.update_password_form}>
            {formErrorsCount > 0 && (
              <ErrorSummary<UpdatePasswordDataType>
                errors={formErrors}
                ids={updatePasswordDataIds.current}
                count={formErrorsCount}
                errorsRef={errorsRef}
              />
            )}
            <form onSubmit={handlePasswordUpdate} noValidate>
              <p>
                Please reset your password and login with the new password after
                creating it
              </p>
              <fieldset>
                <legend>Create Password</legend>
                <PasswordField
                  id={updatePasswordDataIds.current.password}
                  label="Password"
                  name="password"
                  value={formData.password}
                  setValue={handleInputChange}
                  autoComplete="new-password"
                  toggleLabel="Show Password"
                  errMsg={formErrors.password ?? ""}
                  stylesClass={styles.update_password_input_box}
                />
                <PasswordField
                  id={updatePasswordDataIds.current.password2}
                  label="Re-Enter Password"
                  name="password2"
                  value={formData.password2}
                  setValue={handleInputChange}
                  autoComplete="new-password"
                  toggleLabel="Show Re-entered Password"
                  errMsg={formErrors.password2 ?? ""}
                  stylesClass={styles.update_password_input_box}
                />
              </fieldset>
              <div className={styles.update_password_cta}>
                <button
                  type="button"
                  className="secondary button"
                  onClick={handleCancelPasswordUpdate}
                >
                  Cancel
                </button>
                <SubmitButton label="Save Changes" />
              </div>
            </form>
          </div>
        </main>
        {isLoading && (
          // TODO: refactor to HTML component
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
            <Typography role="alert">Creating Password...</Typography>
          </Backdrop>
        )}
      </div>
    </PageTitle>
  );
};
