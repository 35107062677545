import { Ref } from "react";
import { HashLink } from "react-router-hash-link";
import styles from './styles/ErrorSummary.module.css'

interface ErrorSummaryProps<T> {
  count: number;
  errors: T;
  ids: T;
  errorsRef: Ref<HTMLDivElement>;
}

export function ErrorSummary<T extends {}>({
  count,
  errors,
  ids,
  errorsRef,
}: ErrorSummaryProps<T>) {
  const errorsCount = count > 1 ? `were ${count} errors` : "was 1 error";
  return (
    <div role="alert" tabIndex={-1} ref={errorsRef} className={styles.error_summary}>
      <p>
        There {errorsCount} in your form submission. Please fix the following:
      </p>
      <ul>
        {Object.entries(errors).map(([key, error]) => (
          <li key={key}>
            {/* coerced to string to exclude symbol type */}
            <HashLink to={`#${ids[key as keyof T]}`}>{String(error)}</HashLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
