import { Link } from "react-router-dom";
import { RESET_PASSWORD_ENDPOINT } from "../../api/endpoints";
import { PageTitle } from "../../components/PageTitle";
import { EmailForm } from "../../components/EmailForm";
import logo from "../../assets/lera.png";
import styles from "./styles/ExpiredPasswordReset.module.css";

export const ExpiredPasswordResetLink = () => {
  return (
    <PageTitle title="Expired Password Reset Link - Lera API">
      <div className={styles.expired_password_reset_container}>
        <header>
          <Link to="/">
            <img src={logo} alt="Lera API" />
          </Link>
        </header>
        <main>
          <EmailForm
            title="Expired Password Reset Link"
            instruction="The password reset link has expired. Please enter your email address to receive a new link."
            reqEndpoint={RESET_PASSWORD_ENDPOINT}
            notifierUrl="/reset-password/link-notification"
          />
        </main>
      </div>
    </PageTitle>
  );
};
