import { noop } from "../../utils/general";
import { Icon } from "../Icon";

interface TablePaginationProps {
  currPage: number;
  totalPages: number;
  onPageChange: typeof noop;
}

export const TablePagination = ({
  currPage,
  totalPages,
  onPageChange,
}: TablePaginationProps) => {
  const renderAllPgCtrl = (currPage: number, totalPages: number) => {
    return [...Array(totalPages + 1).keys()].slice(1).map((page) => (
      <li key={page}>
        <button
          type="button"
          {...(currPage === page && { "aria-current": "true" })}
        >
          {page}
        </button>
      </li>
    ));
  };

  const renderSelectPgCtrl = (currPage: number, lastPage: number) => {
    return (
      <>
        {currPage <= 3 ? (
          [...Array(currPage).keys()].slice(1).map((page) => (
            <li key={page}>
              <button type="button">{page}</button>
            </li>
          ))
        ) : (
          <>
            <li>
              <button type="button">1</button>
            </li>
            <span data-ellipsis aria-hidden>
              &#8230;
            </span>
            <li>
              <button type="button">{currPage - 1}</button>
            </li>
          </>
        )}
        <li>
          <button type="button" aria-current="true">
            {currPage}
          </button>
        </li>
        {currPage <= lastPage - 3 ? (
          <>
            <li>
              <button type="button">{currPage + 1}</button>
            </li>
            <span data-ellipsis aria-hidden>
              &#8230;
            </span>
            <li>
              <button type="button">{lastPage}</button>
            </li>
          </>
        ) : (
          [...Array(lastPage + 1).keys()].slice(currPage + 1).map((page) => (
            <li key={page}>
              <button type="button">{page}</button>
            </li>
          ))
        )}
      </>
    );
  };

  return (
    <ol aria-label="Table Pagination" onClick={onPageChange}>
      <li>
        <button
          type="button"
          {...(currPage === 1 && { "aria-disabled": "true" })}
        >
          <Icon
            name="ChevronLeft"
            hidden4Sr={false}
            accessible_name="Previous"
          />
        </button>
      </li>
      {totalPages <= 5
        ? renderAllPgCtrl(currPage, totalPages)
        : renderSelectPgCtrl(currPage, totalPages)}
      <li>
        <button
          type="button"
          {...(currPage === totalPages && { "aria-disabled": "true" })}
        >
          <Icon name="ChevronRight" hidden4Sr={false} accessible_name="Next" />
        </button>
      </li>
    </ol>
  );
};
