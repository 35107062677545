export const SidebarData = [
  {
    title: "Dashboard",
    // so nav link is highlighted when users navigate to dashboard from either route
    path: ["/dashboard", "/"],
  },
  {
    title: "Plans",
    path: ["/subscription-plans"],
  },
  {
    title: "Docs",
    path: [""],
  },
  {
    title: "Log Out",
    path: ["/logout"],
  },
];
