import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PlanType } from "../../utils/general";
import { GET_PLAN_DETAILS } from "../../api/endpoints";
import { useCustomAxios } from "../../api/axios";
import { PageTitle } from "../../components/PageTitle";
import { AuthLayout } from "../../components/AuthLayout";
import { SubscriptionCard } from "./components/SubscriptionCard";
import styles from "./styles/SubscriptionPlans.module.css";

export const SubscriptionPlans = () => {
  const [searchParams] = useSearchParams();
  const { makeRequest } = useCustomAxios();
  const [plans, setPlans] = useState<PlanType[]>([]);

  useEffect(() => {
    makeRequest(
      [
        {
          url: GET_PLAN_DETAILS,
          method: "get",
          params: {
            plan_type: "subscription",
          },
        },
      ],
      (responseDataArr) => {
        if (searchParams.get("checkout") === "cancelled") {
          // page rendered after checkout cancellation so no need to keep key data in session storage
          sessionStorage.removeItem("curr_key");
        }
        const [plansData] = responseDataArr;
        setPlans(plansData);
      },
      (error) => console.error(error) // TODO: refactor error handling
    );
  }, []);

  return (
    <PageTitle title="Plans - Lera API">
      <AuthLayout pageH1="Plans">
        <div className={styles.plans_container}>
          <h2>Subscription Plans</h2>
          <p className={styles.instructions}>
            Start building for free, then add a site plan to go live. Account
            plans unlock additional features.
          </p>
          <div className={styles.plans}>
            {plans.map((plan) => {
              const [planInfo, ...planData] = plan.plan_description.split("\n");
              return (
                <SubscriptionCard
                  key={plan.plan_id}
                  name={plan.plan_name}
                  info={planInfo}
                  price={+plan.plan_cost.$numberDecimal}
                  scan_limit={+plan.scan_limit}
                  batch_size={+plan.batch_size}
                  features={planData}
                />
              );
            })}
          </div>
        </div>
      </AuthLayout>
    </PageTitle>
  );
};
