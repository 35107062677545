import { ReactNode, useRef } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { Sidebar } from "../Sidebar";
import { Breadcrumb } from "../Breadcrumb";
import styles from "./AuthLayout.module.css";

interface AuthLayoutProps {
  pageH1?: string;
  breadcrumbLinks?: {
    label: string;
    to: string;
  }[];
  children: ReactNode;
}

// TODO: implement AuthLayout for mobile
export const AuthLayout = ({
  pageH1 = "",
  breadcrumbLinks = [],
  children,
}: AuthLayoutProps) => {
  const { userData } = useAuth();
  const contentRef = useRef<HTMLDivElement>(null);
  const handleSkip2Content = () => {
    contentRef.current?.focus();
  };

  return userData ? (
    <div className={styles.auth_layout}>
      <Link
        to="#content"
        className={`primary button ${styles.skip}`}
        onClick={handleSkip2Content}
      >
        Skip to Content
      </Link>
      <div className={styles.auth_page}>
        <header>
          <div className={styles.sidebar}>
            <Sidebar />
          </div>
          <Link to="/profile" className={styles.profile_link}>
            <span aria-hidden>
              {`${userData.first_name[0].toUpperCase()}${userData.last_name[0].toUpperCase()}`}
            </span>
            <span className="visually-hidden">Profile</span>
          </Link>
        </header>
        <main>
          {pageH1 && (
            <div className={styles.page_heading}>
              <h1>{pageH1}</h1>
            </div>
          )}
          {breadcrumbLinks.length > 0 && (
            <div className={styles.breadcrumb}>
              <Breadcrumb Links={breadcrumbLinks} />
            </div>
          )}
          <div
            tabIndex={-1}
            ref={contentRef}
            className={styles.content_container}
          >
            {children}
          </div>
        </main>
      </div>
    </div>
  ) : (
    // TODO: refactor to re-useable HTML component
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <CircularProgress color="inherit" />
      <Typography role="alert">Loading</Typography>
    </div>
  );
};
