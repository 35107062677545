import { useState } from "react";
import { TRANSACTION_HISTORY_ENDPOINT } from "../../../../../api/endpoints";
import { useCustomAxios } from "../../../../../api/axios";
import { Table } from "../../../../../components/Table";

const transactionDetailsTableHeaders = [
  {
    label: "Invoice ID",
    sortBy: false,
  },
  {
    label: "Plan Name",
    sortBy: true,
  },
  {
    label: "Date",
    sortBy: true,
  },
  {
    label: "Amount",
    sortBy: true,
  },
  {
    label: "Status",
    sortBy: true,
  },
];

interface TransactionDetailsType {
  transaction_id: string;
  invoice_id: string;
  plan_name: string;
  date_of_transaction: string;
  amount: number;
  status: string;
}

export const TransactionDetails = ({ keyValue }: { keyValue: string }) => {
  const { makeRequest } = useCustomAxios();
  const [transactionsData, setTransactionsData] = useState({
    transactions: [] as TransactionDetailsType[],
    pages: 0,
  });

  const renderTransactionDetailsTableBody = () => {
    return (
      <tbody>
        {transactionsData.transactions.map((txn) => {
          return (
            <tr key={txn.transaction_id}>
              <td>{txn.invoice_id ?? ""}</td>
              <td>{txn.plan_name.split("-").at(-1)?.trim()}</td>
              <td>
                {new Date(txn.date_of_transaction).toLocaleDateString("en-US")}
              </td>
              <td>{txn.amount}</td>
              <td>
                <strong data-status={txn.status.toLowerCase()}>
                  {txn.status[0].toUpperCase() + txn.status.slice(1)}
                </strong>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  const fetchTransactions = (
    currPage: number,
    sortHeader: string,
    sortOrder: string
  ) => {
    const orderParam = sortOrder === "ascending" ? "asc" : "desc";
    let sortParam = null;
    switch (sortHeader) {
      case "Amount":
      case "Status":
        sortParam = sortHeader.toLowerCase();
        break;
      case "Plan Name":
        sortParam = "plan_name";
        break;
      default:
        sortParam = "date_of_transaction";
        break;
    }
    makeRequest(
      [
        {
          url: TRANSACTION_HISTORY_ENDPOINT,
          method: "get",
          params: {
            api_key: keyValue,
            page: currPage,
            limit: 10,
            sort_by: sortParam,
            order: orderParam,
          },
        },
      ],
      (responseDataArr) => {
        const [{ transactionHistory, totalPages }] = responseDataArr;
        setTransactionsData({
          transactions: transactionHistory,
          pages: totalPages,
        });
      },
      (error) => console.error(error)
    );
  };

  return (
    <Table
      title="Transaction Details"
      showTitle={false}
      headers={transactionDetailsTableHeaders}
      initialSortHeader="Date"
      fetchTableData={fetchTransactions}
      tableDataLength={transactionsData.transactions.length}
      totalPages={transactionsData.pages}
      renderTableBody={renderTransactionDetailsTableBody}
    />
  );
};
