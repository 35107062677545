import {
  useId,
  useState,
  useRef,
  useEffect,
  FormEvent,
  ChangeEvent,
} from "react";
import { UPDATE_USER_INFO_ENDPOINT } from "../../api/endpoints";
import { useCustomAxios } from "../../api/axios";
import { useAuth } from "../../context/AuthProvider";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { ErrorSummary } from "../../components/ErrorSummary";
import { TextField } from "../../components/TextField";
import { SubmitButton } from "../../components/SubmitButton";

interface UserInfoDataType {
  firstname: string;
  lastname: string;
}

interface UserInfoProps {
  userInfoStylesClass: string;
  textFieldStylesClass: string;
  ctaStylesClass: string;
}

export const UserInfo = ({
  userInfoStylesClass,
  textFieldStylesClass,
  ctaStylesClass,
}: UserInfoProps) => {
  const { makeRequest } = useCustomAxios();
  const { userData, getUserId, updateUserName } = useAuth();
  const [updatingUserInfo, setUpdatingUserInfo] = useState(false);

  const id = useId();
  const userInfoDataIds = useRef<UserInfoDataType>({
    firstname: `firstname-${id}`,
    lastname: `lastname-${id}`,
  });
  const [formData, setFormData] = useState<UserInfoDataType>({
    firstname: userData!.first_name,
    lastname: userData!.last_name,
  });
  const [formErrors, setFormErrors] = useState({} as UserInfoDataType);
  const [formErrorsCount, setFormErrorsCount] = useState<number>(0);
  const errorsRef = useRef<HTMLDivElement>(null);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const validateLoginData = () => {
    let validationErrors = {} as UserInfoDataType;
    let errorsCount = 0;

    if (formData.firstname === "") {
      validationErrors.firstname = "First Name field cannot be empty";
      errorsCount++;
    }

    if (formData.lastname === "") {
      validationErrors.lastname = "Last Name field cannot be empty";
      errorsCount++;
    }
    return { errorsCount, validationErrors };
  };

  // TODO: refactor error handling
  const handleServerError = (error: any) => {
    console.error(error);
  };

  const handleUserInfoUpdate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { errorsCount, validationErrors } = validateLoginData();
    if (errorsCount > 0) {
      setFormErrors(validationErrors);
      setFormErrorsCount(errorsCount);
    } else {
      setUpdatingUserInfo(true);
      makeRequest(
        [
          {
            url: UPDATE_USER_INFO_ENDPOINT,
            method: "post",
            data: {
              user_id: getUserId(userData!),
              first_name: formData.firstname,
              last_name: formData.lastname,
            },
          },
        ],
        () => {
          setUpdatingUserInfo(false);
          updateUserName(formData.firstname, formData.lastname);
        },
        (error: any) => {
          setUpdatingUserInfo(false);
          handleServerError(error);
        }
      );
    }
  };

  const handleCancelUserInfoUpdate = () => {
    setFormData({
      firstname: userData!.first_name,
      lastname: userData!.last_name,
    });
    setFormErrors({} as UserInfoDataType);
    setFormErrorsCount(0);
  };

  useEffect(() => {
    if (formErrorsCount > 0 && errorsRef.current) {
      errorsRef.current.focus();
    }
  }, [formErrorsCount]);

  return updatingUserInfo ? (
    // TODO: refactor to HTML component
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={updatingUserInfo}
    >
      <CircularProgress color="inherit" />
      <Typography role="alert">Updating...</Typography>
    </Backdrop>
  ) : (
    <div className={userInfoStylesClass}>
      {formErrorsCount > 0 && (
        <ErrorSummary<UserInfoDataType>
          errors={formErrors}
          ids={userInfoDataIds.current}
          count={formErrorsCount}
          errorsRef={errorsRef}
        />
      )}
      <p className={textFieldStylesClass}>
        <span>Email</span>
        <span>{userData!.email}</span>
      </p>
      <form onSubmit={handleUserInfoUpdate} noValidate>
        <TextField
          id={userInfoDataIds.current.firstname}
          label="First Name"
          name="firstname"
          value={formData.firstname}
          setValue={handleInputChange}
          autoComplete="given-name"
          errMsg={formErrors.firstname ?? ""}
          stylesClass={textFieldStylesClass}
        />
        <TextField
          id={userInfoDataIds.current.lastname}
          label="Last Name"
          name="lastname"
          value={formData.lastname}
          setValue={handleInputChange}
          autoComplete="family-name"
          errMsg={formErrors.lastname ?? ""}
          stylesClass={textFieldStylesClass}
        />
        <div className={ctaStylesClass}>
          <button
            type="button"
            className="secondary button"
            onClick={handleCancelUserInfoUpdate}
          >
            Cancel
          </button>
          <SubmitButton label="Save Changes" />
        </div>
      </form>
    </div>
  );
};
