// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResetPassword_reset_password_container__wdyw2 {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background-color: var(--backgroundPrimary);
  padding: 0 1rem;
}

.ResetPassword_reset_password_container__wdyw2 header {
  display: flex;
  justify-content: center;
}

.ResetPassword_reset_password_container__wdyw2 img {
  height: 6rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/reset-password/styles/ResetPassword.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,0CAA0C;EAC1C,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".reset_password_container {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 2rem;\n  background-color: var(--backgroundPrimary);\n  padding: 0 1rem;\n}\n\n.reset_password_container header {\n  display: flex;\n  justify-content: center;\n}\n\n.reset_password_container img {\n  height: 6rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reset_password_container": `ResetPassword_reset_password_container__wdyw2`
};
export default ___CSS_LOADER_EXPORT___;
