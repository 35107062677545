import { HomePage } from "../pages/home";
import { Signup } from "../pages/signup";
import { ActivateAccountNotifierPage } from "../pages/activate-account-notifier";
import { ActivateAccountPage } from "../pages/activate-account";
import { ForgotPassword } from "../pages/forgot-password";
import { UpdatePassword } from "../pages/update-password";
import { ResendActivationEmail } from "../pages/resend-activation-email";
import { ExpiredActivationLink } from "../pages/expired-verification";

export const unauthenticatedUserRoutes = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/activate-account",
    children: [
      {
        path: "notification",
        element: <ActivateAccountNotifierPage />,
      },
      {
        path: ":token",
        element: <ActivateAccountPage />,
      },
    ],
  },
  {
    path: "/email-activation",
    element: <ResendActivationEmail />,
  },
  {
    path: "/expired-verification",
    element: <ExpiredActivationLink />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password/:token",
    element: <UpdatePassword />,
  },
];
