import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { ACTIVATE_ACCOUNT_ENDPOINT } from "../../api/endpoints";
import { useCustomAxios } from "../../api/axios";
import { PageTitle } from "../../components/PageTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import checkmark from "../../assets/verified.png";
import styles from "./ActivateAccount.module.css";

export const ActivateAccountPage = () => {
  const { token } = useParams();
  const { makeRequest } = useCustomAxios();
  const [isActivated, setIsActivated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    makeRequest(
      [
        {
          url: ACTIVATE_ACCOUNT_ENDPOINT,
          method: "post",
          data: {
            token: decodeURI(token!),
          },
        },
      ],
      () => {
        setIsActivated(true);
      },
      (error) => {
        if (error.response.data.Error.toLowerCase() === "link expired") {
          navigate("/expired-verification-link", { replace: true });
        }
      }
    );
  }, []);

  return isActivated ? (
    <PageTitle title="Account Activated - Lera API">
      <div className={styles.wrapper}>
        <div className={styles.content_container}>
          <img src={checkmark} alt="" className={styles.verified_image} />
          <h1 className={styles.success_text}>Account Activated</h1>
        </div>
        <div className={styles.redirect_text_container}>
          <p>
            Your Lera account has been successfully activated. Please log in to
            the application.
          </p>
        </div>
        <Link to="/login" className={`${styles.login} primary button`}>
          Log In
        </Link>
      </div>
    </PageTitle>
  ) : (
    // TODO: refactor to re-useable HTML component
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <CircularProgress color="inherit" />
      <Typography role="alert">Activating Your Account</Typography>
    </div>
  );
};
