import { FormEvent, useEffect, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { PlanType } from "../../utils/general";
import {
  GET_PLAN_DETAILS,
  CREATE_TOPUP_CHECKOUT_SESSION_ENDPOINT,
} from "../../api/endpoints";
import { useCustomAxios } from "../../api/axios";
import { PageTitle } from "../../components/PageTitle";
import { AuthLayout } from "../../components/AuthLayout";
import { SubmitButton } from "../../components/SubmitButton";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import styles from "./styles/TopupPlans.module.css";

export const TopupPlans = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const {
    current: { keyValue, keyPlan },
  } = useRef({
    ...(location.state ??
      JSON.parse(sessionStorage.getItem("curr_key") as string)), // on topup cancellation
  });

  const [plans, setPlans] = useState<PlanType[]>([]);
  // undefined coz value prop doesn't accept null
  const [selectedPlanCost, setSelectedPlanCost] = useState<number | undefined>(
    undefined
  );

  // distinguishes user topup request from request for topup plans
  const isTopupRequest = useRef(false);
  const { isLoading, makeRequest } = useCustomAxios();
  const handleTopup = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (keyPlan === "trial") return; // can't top up a trial key

    isTopupRequest.current = true;
    makeRequest(
      [
        {
          url: CREATE_TOPUP_CHECKOUT_SESSION_ENDPOINT,
          method: "post",
          data: {
            plan_name: "Add credits",
            api_key: keyValue,
            topup_price: selectedPlanCost,
          },
        },
      ],
      (responseDataArr) => {
        // save key and plan for redirects on topup success and cancellation
        sessionStorage.setItem(
          "curr_key",
          JSON.stringify({ keyValue: keyValue, keyPlan: keyPlan })
        );
        const [topupData] = responseDataArr;
        window.location.href = topupData.url;
      },
      (error) => console.error(error)
    );
  };

  useEffect(() => {
    makeRequest(
      [
        {
          url: GET_PLAN_DETAILS,
          method: "get",
          params: {
            plan_type: "top-up",
          },
        },
      ],
      (responseDataArr) => {
        if (searchParams.get("checkout") === "cancelled") {
          // page rendered after topup cancellation so no need to keep key data in session storage
          sessionStorage.removeItem("curr_key");
        }
        const plansData = responseDataArr[0] as PlanType[];
        setPlans(plansData);
        setSelectedPlanCost(+plansData[0].plan_cost.$numberDecimal);
      },
      (error) => console.error(error)
    );
  }, []);

  return (
    <PageTitle title="Pay As You Go - Lera API">
      <AuthLayout>
        <div className={styles.topup_container}>
          <h1>Pay as you go</h1>
          {plans.length > 0 && (
            <form onSubmit={handleTopup} noValidate>
              <p>
                To pay online, please select the number of credits you wish to
                purchase:
              </p>
              <div className={styles.topup}>
                <label htmlFor="topup-options">Select Amount</label>
                <div className={styles.topup_options}>
                  <select
                    id="topup-options"
                    value={selectedPlanCost}
                    onChange={(e) => setSelectedPlanCost(+e.target.value)}
                  >
                    {plans.map((plan) => (
                      <option
                        key={plan.plan_id}
                        value={+plan.plan_cost.$numberDecimal}
                      >
                        {`$${plan.plan_cost.$numberDecimal} for ${plan.scan_limit} scans`}
                      </option>
                    ))}
                  </select>
                </div>
                <SubmitButton
                  label="Proceed To Payment"
                  iconRight="ArrowRight"
                />
              </div>
            </form>
          )}
        </div>
        {isLoading && isTopupRequest.current && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
            <Typography role="alert">Loading Checkout...</Typography>
          </Backdrop>
        )}
      </AuthLayout>
    </PageTitle>
  );
};
