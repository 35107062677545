import {
  useId,
  useState,
  useRef,
  useEffect,
  ChangeEvent,
  FormEvent,
} from "react";
import { useNavigate } from "react-router-dom";
import { useCustomAxios } from "../../api/axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { TextField } from "../TextField";
import { SubmitButton } from "../SubmitButton";
import styles from "./styles/EmailForm.module.css";

interface EmailFormProps {
  title: string;
  instruction: string;
  reqEndpoint: string;
  notifierUrl: string;
}

export const EmailForm = ({
  title,
  instruction,
  reqEndpoint,
  notifierUrl,
}: EmailFormProps) => {
  const id = useId();
  const textFieldRef = useRef<HTMLInputElement>(null!);
  const [email, setEmail] = useState<{ value: string; error: null | string }>({
    value: "",
    error: null,
  });
  const handleEmailValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail((prevEmailData) => ({
      ...prevEmailData,
      value: e.target.value,
    }));
  };
  const navigate = useNavigate();
  const { isLoading, makeRequest } = useCustomAxios();
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (email.value === "") {
      setEmail((prevEmailData) => ({
        ...prevEmailData,
        error: "You must enter an email address",
      }));
    } else if (!email.value.match(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i)) {
      setEmail((prevEmailData) => ({
        ...prevEmailData,
        error: "You must enter a valid email address",
      }));
    } else {
      makeRequest(
        [
          {
            url: reqEndpoint,
            method: "post",
            data: email.value,
          },
        ],
        () => navigate(notifierUrl),
        (error) => console.error(error)
      );
    }
  };

  useEffect(() => {
    if (email.error) {
      textFieldRef.current.focus();
    }
  }, [email.error]);

  return (
    <>
      <div className={styles.email_form_container}>
        <h1>{title}</h1>
        <div className={styles.email_form}>
          <p>{instruction}</p>
          <form onSubmit={handleSubmit} noValidate>
            <TextField
              id={id}
              label="Email Address"
              type="email"
              name="email"
              value={email.value}
              setValue={handleEmailValueChange}
              autoComplete="email"
              errMsg={email.error ?? ""}
              stylesClass={styles.email}
              ref={textFieldRef}
            />
            <SubmitButton />
          </form>
        </div>
      </div>
      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
          <Typography role="alert">Loading</Typography>
        </Backdrop>
      )}
    </>
  );
};
