// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResetPasswordLinkNotifier_reset_link_sent_container__J4ZDT {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background-color: var(--backgroundPrimary);
  padding: 0 1rem;
}

.ResetPasswordLinkNotifier_reset_link_sent_header__sSzye {
  display: flex;
  justify-content: center;
}

.ResetPasswordLinkNotifier_reset_link_sent_header__sSzye img {
  height: 6rem;
}

.ResetPasswordLinkNotifier_reset_link_sent_content_heading__6\\+oD7 {
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
}

.ResetPasswordLinkNotifier_reset_link_sent_textbox__UZi3Q {
  max-width: 27rem;
  margin-top: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.3125rem rgba(99, 99, 99, 0.376);
  background-color: var(--backgroundSecondary);
  padding: 1.5rem;
  font-weight: 500;
}

@media screen and (min-width: 48em) {
  .ResetPasswordLinkNotifier_reset_link_sent_textbox__UZi3Q {
    padding: 2rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/reset-password-link-notifier/styles/ResetPasswordLinkNotifier.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,0CAA0C;EAC1C,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,qBAAqB;EACrB,wDAAwD;EACxD,4CAA4C;EAC5C,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".reset_link_sent_container {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 2rem;\n  background-color: var(--backgroundPrimary);\n  padding: 0 1rem;\n}\n\n.reset_link_sent_header {\n  display: flex;\n  justify-content: center;\n}\n\n.reset_link_sent_header img {\n  height: 6rem;\n}\n\n.reset_link_sent_content_heading {\n  font-weight: 700;\n  font-size: 1.5rem;\n  text-align: center;\n}\n\n.reset_link_sent_textbox {\n  max-width: 27rem;\n  margin-top: 1.5rem;\n  border-radius: 0.5rem;\n  box-shadow: 0 0.125rem 0.3125rem rgba(99, 99, 99, 0.376);\n  background-color: var(--backgroundSecondary);\n  padding: 1.5rem;\n  font-weight: 500;\n}\n\n@media screen and (min-width: 48em) {\n  .reset_link_sent_textbox {\n    padding: 2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reset_link_sent_container": `ResetPasswordLinkNotifier_reset_link_sent_container__J4ZDT`,
	"reset_link_sent_header": `ResetPasswordLinkNotifier_reset_link_sent_header__sSzye`,
	"reset_link_sent_content_heading": `ResetPasswordLinkNotifier_reset_link_sent_content_heading__6+oD7`,
	"reset_link_sent_textbox": `ResetPasswordLinkNotifier_reset_link_sent_textbox__UZi3Q`
};
export default ___CSS_LOADER_EXPORT___;
