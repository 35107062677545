import { DashboardPage } from "../pages/dashboard";
import { KeyDetailsPage } from "../pages/key-details";
import { SubscriptionPlansPage } from "../pages/subscription-plans";
import { TopupPlansPage } from "../pages/topup-plans";
import { CheckoutPage } from "../pages/checkout";
import { CheckoutSuccessPage } from "../pages/checkout-success";
import { ProfilePage } from "../pages/profile";

export const authenticatedUserRoutes = [
  // allow authenticated user access to dashboard page from the root and dashboard routes
  {
    path: "/",
    element: <DashboardPage />,
  },
  {
    path: "/dashboard",
    children: [
      {
        index: true,
        element: <DashboardPage />,
      },
      {
        path: "key-details",
        element: <KeyDetailsPage />,
      },
      {
        path: "topup-plans",
        element: <TopupPlansPage />,
      },
    ],
  },
  {
    path: "/subscription-plans",
    element: <SubscriptionPlansPage />,
  },
  {
    path: "/checkout",
    children: [
      {
        path: ":plan",
        element: <CheckoutPage />,
      },
      {
        path: "success",
        element: <CheckoutSuccessPage />,
      },
    ],
  },
  {
    path: "/profile",
    element: <ProfilePage />,
  },
];
