import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { noop } from "../../../utils/general";

export const handleInactiveUserError = (
  errorType: string,
  currentAttempts: number,
  lockUser: typeof noop
) => {
  let errorMsg: ReactNode;
  switch (errorType) {
    case "user not found":
      errorMsg = "No account found. Please Sign Up to create an account.";
      break;
    case "not verified":
      errorMsg = (
        <>
          Account not activated. Please check your email. If you haven't
          received an account activation email,{" "}
          <Link to="/resend-activation-email">please request it</Link>.
        </>
      );
      break;
    default:
      if (currentAttempts < 5) {
        errorMsg = `Email or Password is incorrect. You have ${
          5 - currentAttempts
        } of 5 tries left.`;
      } else {
        lockUser();
        errorMsg = (
          <>
            Maximum number of attempts exceeded.
            <Link to="/reset-password">Please reset your password</Link>.
          </>
        );
      }
      break;
  }
  return errorMsg;
};
