// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TopupPlans_topup_container__s4snw {
  border-radius: 0.625rem;
  background: var(--backgroundSecondary);
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.TopupPlans_topup_container__s4snw h1 {
  font-size: 2rem;
  font-weight: 600;
}

.TopupPlans_topup_container__s4snw form {
  display: contents;
}

.TopupPlans_topup_container__s4snw p {
  font-size: 1rem;
  font-weight: 500;
}

.TopupPlans_topup__fNiVB {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.TopupPlans_topup__fNiVB label {
  width: max-content; /* prevents clicking whitespace beside label from activating select */
  font-weight: 600;
}

.TopupPlans_topup__fNiVB button {
  width: max-content;
}

.TopupPlans_topup_options__OQvvW {
  border: 0.0625rem solid var(--borderSecondary);
  border-radius: 0.5rem;
  padding: 0.5rem;
  font-size: 1rem;
}

.TopupPlans_topup_options__OQvvW select {
  border: none;
  outline: none;
}

.TopupPlans_topup_options__OQvvW:focus-within {
  outline: 0.0625rem solid;
  box-shadow: 0 0 0 0.125rem var(--backgroundSecondary),
    0 0 0 0.25rem var(--base);
}

@media screen and (min-width: 37.5em) {
  .TopupPlans_topup_container__s4snw {
    padding: 1.25rem 0.75rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/topup-plans/styles/TopupPlans.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,sCAAsC;EACtC,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,kBAAkB,EAAE,qEAAqE;EACzF,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,8CAA8C;EAC9C,qBAAqB;EACrB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,wBAAwB;EACxB;6BAC2B;AAC7B;;AAEA;EACE;IACE,wBAAwB;EAC1B;AACF","sourcesContent":[".topup_container {\n  border-radius: 0.625rem;\n  background: var(--backgroundSecondary);\n  padding: 1.5rem 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 1rem;\n}\n\n.topup_container h1 {\n  font-size: 2rem;\n  font-weight: 600;\n}\n\n.topup_container form {\n  display: contents;\n}\n\n.topup_container p {\n  font-size: 1rem;\n  font-weight: 500;\n}\n\n.topup {\n  display: flex;\n  flex-direction: column;\n  gap: 0.25rem;\n}\n\n.topup label {\n  width: max-content; /* prevents clicking whitespace beside label from activating select */\n  font-weight: 600;\n}\n\n.topup button {\n  width: max-content;\n}\n\n.topup_options {\n  border: 0.0625rem solid var(--borderSecondary);\n  border-radius: 0.5rem;\n  padding: 0.5rem;\n  font-size: 1rem;\n}\n\n.topup_options select {\n  border: none;\n  outline: none;\n}\n\n.topup_options:focus-within {\n  outline: 0.0625rem solid;\n  box-shadow: 0 0 0 0.125rem var(--backgroundSecondary),\n    0 0 0 0.25rem var(--base);\n}\n\n@media screen and (min-width: 37.5em) {\n  .topup_container {\n    padding: 1.25rem 0.75rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topup_container": `TopupPlans_topup_container__s4snw`,
	"topup": `TopupPlans_topup__fNiVB`,
	"topup_options": `TopupPlans_topup_options__OQvvW`
};
export default ___CSS_LOADER_EXPORT___;
