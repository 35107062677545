import React, { useEffect, useState } from "react";
import styles from "../../stylesheets/ResetPassword.module.css";
import logo from "../../assets/lera.png";
import { Icon } from "../../components/Icon";
import axios from "axios";
import { RESET_PASSWORD_ENDPOINT } from "../../api/endpoints";
import { useNavigate, useParams } from "react-router-dom";
//import ErrorModal from "../../components/Modals/errorModal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { PageTitle } from "../../components/PageTitle";

export const ResetPasswordPage = () => {
  let { token } = useParams();

  const [password, setPassword] = useState<string | undefined>();
  const [password2, setPassword2] = useState<string | undefined>();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPassword2, setShowPassword2] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<any>({});
  const [errorCount, setErrorCount] = useState<number>(0);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [errorIsOpen, setErrorIsOpen] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string | undefined>("");

  const passwordToggle = () => {
    setShowPassword(!showPassword);
  };

  const password2Toggle = () => {
    setShowPassword2(!showPassword2);
  };

  const navigate = useNavigate();

  // useEffect(() => {
  const ResetPassword = async () => {
    try {
      if (!token) throw new Error("Token not found");
      let data = { token: decodeURI(token), password: password };
      let res = await axios.post(RESET_PASSWORD_ENDPOINT, data);

      if (res.status === 200) {
        navigate("/login");
        setIsOpen(false);
      }
    } catch (error: any) {
      errorHandleOpen();
      setServerError(error.response.data.Error);
    }
  };
  //     verifyEmail()
  // }, [token])

  const validate = () => {
    let errors = { password: "", password2: "" };
    let count = 0;

    if (!password) {
      errors.password = "You must enter a valid password";
      count++;
    }
    if (!password2) {
      errors.password2 = "You must re-enter your password";
      count++;
    } else if (password2 !== password) {
      errors.password2 = "Your password does not match";
      count++;
    }
    setErrorCount(count);
    return errors;
  };

  const errorHandleOpen = () => {
    setErrorIsOpen(true);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormErrors(validate());
    setIsSubmitting(true);
  };

  const handleClose = () => {
    setErrorIsOpen(false);
    setIsSubmitting(false);
    setIsOpen(false);
  };

  useEffect(() => {
    if (errorCount === 0 && isSubmitting) {
      ResetPassword();
      setIsOpen(true);
    }
  }, [formErrors]);

  return (
    <PageTitle title="Reset Password - Lera API">
      <div className={styles.login_container}>
        <img src={logo} alt="Lera API" className={styles.logo} />
        <div className={styles.login_text}>Reset password</div>
        <div className={styles.login_box}>
          <span className={styles.message}>
            Please reset your your password
          </span>
          <form
            className={styles.form_container}
            onSubmit={(e) => handleSubmit(e)}
            noValidate
          >
            <div className={styles.input_name}>
              Enter new Password
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                name="password"
                value={password ? password : ""}
                className={styles.input_field}
                style={
                  formErrors.password ? { border: "1px solid #A12F2B" } : {}
                }
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span className={styles.password_toggle} onClick={passwordToggle}>
                <Icon name={showPassword ? "Eye" : "EyeSlash"} />
              </span>
              {formErrors.password && (
                <span className={styles.error}>{formErrors.password}</span>
              )}
            </div>
            <div className={styles.input_name}>
              Confirm new Password
              <input
                type={showPassword2 ? "text" : "password"}
                placeholder="Re-enter Password"
                name="password2"
                value={password2 ? password2 : ""}
                className={styles.input_field}
                style={
                  formErrors.password2 ? { border: "1px solid #A12F2B" } : {}
                }
                onChange={(e) => setPassword2(e.target.value)}
                required
              />
              <span
                className={styles.password_toggle}
                onClick={password2Toggle}
              >
                <Icon name={showPassword2 ? "Eye" : "EyeSlash"} />
              </span>
              {formErrors.password2 && (
                <span className={styles.error}>{formErrors.password2}</span>
              )}
            </div>
            <div className={styles.btn_container}>
              <button type="submit" className={styles.signin_button}>
                Submit
              </button>
            </div>
          </form>
        </div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {/*ErrorModal(serverError, errorIsOpen, handleClose)*/}
      </div>
    </PageTitle>
  );
};
