import {
  useEffect,
  useState,
  useRef,
  useId,
  ChangeEvent,
  FormEvent,
  ReactNode,
} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  LOGIN_ENDPOINT,
  UPDATE_USER_STATUS_ENDPOINT,
} from "../../api/endpoints";
import { useCustomAxios } from "../../api/axios";
import { useAuth } from "../../context/AuthProvider";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { PageTitle } from "../../components/PageTitle";
import { TextField } from "../../components/TextField";
import { PasswordField } from "../../components/PasswordField";
import { SubmitButton } from "../../components/SubmitButton";
import { ErrorSummary } from "../../components/ErrorSummary";
import { ErrorModal } from "../../components/ErrorModal";
import logo from "../../assets/lera.png";
import { handleInactiveUserError } from "./utils/helper";
import styles from "./styles/Login.module.css";

interface LoginDataType {
  email: string;
  password: string;
}

export const Login = () => {
  const id = useId();
  const loginDataIds = useRef<LoginDataType>({
    email: `email-${id}`,
    password: `password-${id}`,
  });

  const [formData, setFormData] = useState<LoginDataType>({
    email: "",
    password: "",
  });

  const [formErrors, setFormErrors] = useState({} as LoginDataType);
  const [formErrorsCount, setFormErrorsCount] = useState<number>(0);
  const [errorModalMsg, setErrorModalMsg] = useState<ReactNode>(null);
  const errorsRef = useRef<HTMLDivElement>(null);
  const loginButtonRef = useRef<HTMLButtonElement>(null);
  const loginAttemptsRef = useRef(0);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  //TODO: refactor email validation, implement password strength validation
  const validateLoginData = () => {
    let validationErrors = {} as LoginDataType;
    let errorsCount = 0;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (formData.email === "") {
      validationErrors.email = "You must enter an email address";
      errorsCount++;
    } else if (!regex.test(formData.email)) {
      validationErrors.email = "You must enter a valid email address";
      errorsCount++;
    }

    if (formData.password === "") {
      validationErrors.password = "You must enter a password";
      errorsCount++;
    }
    return { errorsCount, validationErrors };
  };

  const { isLoading, makeRequest } = useCustomAxios();
  const { login, isAuthenticated } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname ?? "/";

  const handleServerError = (error: any) => {
    let errorMsg: ReactNode;
    if (!error.response) {
      errorMsg = "Oops! Something went wrong. Please try again later.";
    } else {
      const errorData = error.response.data;
      const lockUser = () => {
        makeRequest(
          [
            {
              url: UPDATE_USER_STATUS_ENDPOINT,
              method: "post",
              data: {
                user_id: errorData.user_id,
                user_status: "locked",
              },
            },
          ],
          (responseDataArr) => console.log(responseDataArr),
          (error) => console.error(error)
        );
      };
      switch (errorData.user_status) {
        case "locked":
          errorMsg = (
            <>
              Maximum number of login attempts exceeded.
              <Link to="/reset-password">Please reset your password</Link>.
            </>
          );
          break;
        default: // inactive user status isn't returned consistently
          errorMsg = handleInactiveUserError(
            errorData.Error.toLowerCase(),
            loginAttemptsRef.current,
            lockUser
          );
          break;
      }
    }
    if (errorMsg) setErrorModalMsg(errorMsg);
  };

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { errorsCount, validationErrors } = validateLoginData();

    if (errorsCount > 0) {
      setFormErrors(validationErrors);
      setFormErrorsCount(errorsCount);
    } else {
      loginAttemptsRef.current++;
      makeRequest(
        [
          {
            url: LOGIN_ENDPOINT,
            method: "post",
            data: formData,
          },
        ],
        (responseDataArr) => {
          const [responseData] = responseDataArr;
          login(responseData);
          navigate(from, { replace: true });
        },
        handleServerError
      );
    }
  };

  const handleErrorModalClose = () => {
    setErrorModalMsg(null);
    loginButtonRef.current?.focus();
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }

    if (formErrorsCount > 0 && errorsRef.current) {
      errorsRef.current.focus();
    }
  }, [formErrorsCount]);

  return (
    <PageTitle title="Login - Lera API">
      <div className={styles.login_container}>
        <header>
          <Link to="/">
            <img src={logo} alt="Lera API" className={styles.logo} />
          </Link>
        </header>
        <main>
          <div className={styles.login_intro}>
            <h1 className={styles.login_text}>Log in to your account</h1>
            <p className={styles.login_redirect_wrapper}>
              Or{" "}
              <Link to="/signup" className={styles.login_redirect}>
                create your account today
              </Link>
            </p>
          </div>
          <div className={styles.login_box}>
            {formErrorsCount > 0 && (
              <ErrorSummary<LoginDataType>
                errors={formErrors}
                ids={loginDataIds.current}
                count={formErrorsCount}
                errorsRef={errorsRef}
              />
            )}
            <form onSubmit={handleLogin} noValidate>
              <p>All fields are required</p>
              <TextField
                id={loginDataIds.current.email}
                label="Email Address"
                type="email"
                name="email"
                value={formData.email}
                setValue={handleInputChange}
                autoComplete="email"
                errMsg={formErrors.email ?? ""}
                stylesClass={styles.input_box}
              />
              <PasswordField
                id={loginDataIds.current.password}
                label="Password"
                name="password"
                value={formData.password}
                setValue={handleInputChange}
                autoComplete="current-password"
                toggleLabel="Show Password"
                errMsg={formErrors.password ?? ""}
                stylesClass={styles.input_box}
              />
              <div className={styles.password_auth}>
                <div className={styles.checkbox}>
                  <input type="checkbox" name="remember" id="remember" />
                  <label htmlFor="remember">Remember me</label>
                </div>
                <Link
                  to="/forgot-password"
                  className={styles.forgot_password_redirect}
                >
                  Forgot Your Password?
                </Link>
              </div>
              <SubmitButton label="Log In" ref={loginButtonRef} />
            </form>
          </div>
        </main>
        {isLoading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
            <Typography role="alert">Loading</Typography>
          </Backdrop>
        )}
        {errorModalMsg && (
          <ErrorModal message={errorModalMsg} onClose={handleErrorModalClose} />
        )}
      </div>
    </PageTitle>
  );
};
