import { useEffect, useState } from "react";
import {
  KEY_AUDITS_ENDPOINT,
  TRANSACTION_HISTORY_ENDPOINT,
} from "../../api/endpoints";
import { APIKeyType } from "../../utils/general";
import { useCustomAxios } from "../../api/axios";
import { Table } from "../../components/Table";

interface ScanHistoryType {
  _id: { $oid: string };
  created_at: { $date: { $numberLong: number } };
  updated_at: { $date: { $numberLong: number } };
  num_pages: number;
  status: string;
}

export const ScanHistoryTable = ({ apiKey }: { apiKey: APIKeyType }) => {
  const { makeRequest } = useCustomAxios();
  const [scansData, setScansData] = useState({
    scans: [] as ScanHistoryType[],
    pages: 0,
  });

  const scanHistoryTableHeaders = [
    {
      label: "Scan ID",
      sortBy: false,
    },
    {
      label: "Date Created",
      sortBy: true,
    },
    {
      label: "Pages",
      sortBy: false,
    },
    {
      label: "Status",
      sortBy: true,
    },
  ];

  const renderScanHistoryTableBody = () => {
    return (
      <tbody>
        {scansData.scans.map((scan) => {
          return (
            <tr key={scan._id.$oid}>
              <td>{scan._id.$oid}</td>
              <td>
                {new Date(
                  +scan.created_at.$date.$numberLong
                ).toLocaleDateString("en-US")}
              </td>
              <td>{scan.num_pages}</td>
              <td>
                <strong data-status={scan.status.toLowerCase()}>
                  {scan.status[0].toUpperCase() + scan.status.slice(1)}
                </strong>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  const fetchScans = (
    currPage: number,
    sortHeader: string,
    sortOrder: string
  ) => {
    const sortParam = sortHeader === "Date Created" ? "created_at" : "status";
    const orderParam = sortOrder === "ascending" ? "asc" : "desc";
    makeRequest(
      [
        {
          url: KEY_AUDITS_ENDPOINT,
          method: "get",
          params: {
            api_key: apiKey,
            page: currPage,
            limit: 10,
            sort_by: sortParam,
            order: orderParam,
          },
        },
      ],
      (responseDataArr) => {
        const [scans, pages] = responseDataArr[0];
        setScansData({ scans, pages });
      },
      (error) => console.error(error) // TODO: refactor error handling
    );
  };

  return (
    <Table
      title="Scan History"
      showTitle={false}
      headers={scanHistoryTableHeaders}
      initialSortHeader="Date Created"
      fetchTableData={fetchScans}
      tableDataLength={scansData.scans.length}
      totalPages={scansData.pages}
      renderTableBody={renderScanHistoryTableBody}
    />
  );
};

interface TransactionHistoryType {
  transaction_id: string;
  invoice_id: string;
  plan_name: string;
  date_of_transaction: string;
  amount: number;
  status: string;
}

interface TransactionDetailsTableProps {
  apiKey: APIKeyType;
  checkoutStatus: string;
}

export const TransactionDetailsTable = ({
  apiKey,
  checkoutStatus,
}: TransactionDetailsTableProps) => {
  const { makeRequest } = useCustomAxios();
  const [transactionsData, setTransactionsData] = useState({
    transactions: [] as TransactionHistoryType[],
    pages: 0,
  });

  const transactionDetailsTableHeaders = [
    {
      label: "Invoice ID",
      sortBy: false,
    },
    {
      label: "Plan Name",
      sortBy: true,
    },
    {
      label: "Date",
      sortBy: true,
    },
    {
      label: "Amount",
      sortBy: true,
    },
    {
      label: "Status",
      sortBy: true,
    },
  ];

  const renderTransactionDetailsTableBody = () => {
    return (
      <tbody>
        {transactionsData.transactions.map((txn) => {
          return (
            <tr key={txn.transaction_id}>
              <td>{txn.invoice_id ?? ""}</td>
              <td>{txn.plan_name.split("-")[1]?.trim()}</td>
              <td>
                {new Date(txn.date_of_transaction).toLocaleDateString("en-US")}
              </td>
              <td>{(txn.amount / 100).toFixed(2)}</td>
              <td>
                <strong data-status={txn.status.toLowerCase()}>
                  {txn.status[0].toUpperCase() + txn.status.slice(1)}
                </strong>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  const fetchTransactions = (
    currPage: number,
    sortHeader: string,
    sortOrder: string
  ) => {
    const orderParam = sortOrder === "ascending" ? "asc" : "desc";
    let sortParam;
    switch (sortHeader) {
      case "Amount":
      case "Status":
        sortParam = sortHeader.toLowerCase();
        break;
      case "Plan Name":
        sortParam = "plan_name";
        break;
      default:
        sortParam = "date_of_transaction";
        break;
    }
    makeRequest(
      [
        {
          url: TRANSACTION_HISTORY_ENDPOINT,
          method: "get",
          params: {
            api_key: apiKey,
            page: currPage,
            limit: 10,
            sort_by: sortParam,
            order: orderParam,
          },
        },
      ],
      (responseDataArr) => {
        const { transactionHistory, totalPages } = responseDataArr[0];
        setTransactionsData({
          transactions: transactionHistory,
          pages: totalPages,
        });
      },
      (error) => console.error(error) // TODO: refactor error handling
    );
  };

  // re-fetches txn data on checkout initiation and completion
  useEffect(() => {
    if (checkoutStatus) {
      fetchTransactions(1, "Date", "descending");
    }
  }, [checkoutStatus]);

  return (
    <Table
      title="Transaction Details"
      showTitle={false}
      headers={transactionDetailsTableHeaders}
      initialSortHeader="Date"
      fetchTableData={fetchTransactions}
      tableDataLength={transactionsData.transactions.length}
      totalPages={transactionsData.pages}
      renderTableBody={renderTransactionDetailsTableBody}
    />
  );
};
