import { Dashboard } from "../pages/dashboard";
import { KeyDetails } from "../pages/key-details";
import { SubscriptionPlans } from "../pages/subscription-plans";
import { TopupPlans } from "../pages/topup-plans";
import { CancelCheckout } from "../pages/cancel-checkout";
import { ProfilePage } from "../pages/profile";

export const authenticatedUserRoutes = [
  // allow authenticated user access to dashboard page from the root and dashboard routes
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/dashboard",
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "key-details",
        element: <KeyDetails />,
      },
      {
        path: "topup-plans",
        element: <TopupPlans />,
      },
    ],
  },
  {
    path: "/subscription-plans",
    element: <SubscriptionPlans />,
  },
  {
    path: "/cancel-checkout",
    element: <CancelCheckout />,
  },
  {
    path: "/profile",
    element: <ProfilePage />,
  },
];
