import { Link } from "react-router-dom";
import { PageTitle } from "../../components/PageTitle";
import logo from "../../assets/lera.svg";
import styles from "./Home.module.css";

export const HomePage = () => {
  return (
    <PageTitle title="Lera API">
      <div className={styles.home_container}>
        <h1 className={styles.img_container}>
          <img
            src={logo}
            alt="Lera, Your Accessibility Reporting Experience"
            className={styles.img_container}
          />
        </h1>
        <Link to="/signup" className={`${styles.login_signup_button}`}>
          Sign Up
        </Link>
        <Link to="/login" className={`${styles.login_signup_button}`}>
          Log In
        </Link>
      </div>
    </PageTitle>
  );
};
