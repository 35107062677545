import {
  CSSProperties,
  ReactNode,
  MouseEvent,
  useId,
  useRef,
  useState,
  useEffect,
} from "react";
import { noop } from "../../utils/general";
import { Icon } from "../Icon";
import { TablePagination } from "./TablePagination";
import "./Table.module.css";

type sortOrderType = "ascending" | "descending";

interface ScreenReaderNotifier {
  (header: string, order: sortOrderType): void;
  (currPage: number, totalPages: number): void;
}

interface TableProps {
  title: string;
  showTitle?: boolean;
  headers: {
    label: string;
    sortBy: boolean;
  }[];
  initialSortHeader: string;
  instructions?: string;
  fetchTableData: typeof noop;
  tableDataLength: number;
  totalPages: number;
  renderTableBody: () => ReactNode;
}

export const Table = ({
  title,
  showTitle = true,
  headers,
  initialSortHeader,
  instructions = "",
  fetchTableData,
  tableDataLength,
  totalPages,
  renderTableBody,
}: TableProps) => {
  const id = useId();
  const notifierRef = useRef<HTMLDivElement>(null!);
  const [sortHeader, setSortHeader] = useState(initialSortHeader);
  const [sortOrder, setSortOrder] = useState<sortOrderType>("descending");
  const [currPage, setCurrPage] = useState(1);
  const isPageChange = useRef(false);

  const isTableWithData = tableDataLength > 0;

  const notification4ScreenReaders: ScreenReaderNotifier = (
    param1: string | number,
    param2: sortOrderType | number
  ) => {
    notifierRef.current.textContent =
      typeof param1 === "number"
        ? `Showing ${param1} of ${param2} pages`
        : `Sorting by ${param1} in ${param2} order`;
    // clear after notifying
    setTimeout(() => {
      notifierRef.current.textContent = "";
    }, 1000);
  };

  const handleSort = (e: MouseEvent<HTMLTableCellElement>) => {
    if (tableDataLength <= 1) return; // can't sort table with no or one row of data
    const [, activatedHeader] = e.currentTarget.id.split("-");
    const activatedOrder =
      activatedHeader !== sortHeader
        ? "descending"
        : sortOrder === "descending"
        ? "ascending"
        : "descending";
    notification4ScreenReaders(activatedHeader, activatedOrder);
    setSortHeader(activatedHeader);
    setSortOrder(activatedOrder);
  };

  const handlePageChange = (e: MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLElement;
    const activatedPage = target.textContent;
    let newPage: number | undefined;
    switch (activatedPage) {
      case "Previous":
        if (currPage !== 1) newPage = currPage - 1;
        break;
      case "Next":
        if (currPage !== totalPages) newPage = currPage + 1;
        break;
      case "…":
        break;
      default:
        newPage = +activatedPage!;
        break;
    }
    if (newPage) {
      isPageChange.current = true;
      setCurrPage(newPage);
    }
  };

  useEffect(() => {
    fetchTableData(currPage, sortHeader, sortOrder);
    if (isPageChange.current) {
      isPageChange.current = false; //reset
      notification4ScreenReaders(currPage, totalPages);
    }
  }, [currPage, sortHeader, sortOrder]);

  return (
    <>
      <table style={{ "--table-columns": headers.length } as CSSProperties}>
        <caption {...(!showTitle && { hidden: true })}>
          <span data-table-heading>{title}</span>
          {instructions && <span>{instructions}</span>}
        </caption>
        <thead>
          <tr>
            {headers.map((header) => {
              return header.sortBy ? (
                <th
                  key={header.label}
                  id={`${id}-${header.label}`}
                  scope="col"
                  {...(isTableWithData &&
                    header.label === sortHeader && { "aria-sort": sortOrder })}
                  onClick={handleSort}
                >
                  <button>
                    <span>{header.label}</span>
                    <span>
                      <Icon name="ChevronUp" />
                      <Icon name="ChevronDown" />
                    </span>
                  </button>
                </th>
              ) : (
                <th key={header.label} id={`${id}-${header.label}`} scope="col">
                  {header.label}
                </th>
              );
            })}
          </tr>
        </thead>
        {isTableWithData && renderTableBody()}
      </table>
      {isTableWithData && (
        <div data-table-pagination>
          <span aria-hidden>
            {`Showing ${currPage} of ${totalPages} pages`}
          </span>
          <TablePagination
            currPage={currPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      )}
      <div role="alert" className="visually-hidden" ref={notifierRef}></div>
    </>
  );
};
