// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExpiredVerificationLink_expired_verification_container__VmMi8 {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background-color: var(--backgroundPrimary);
  padding: 0 1rem;
}

.ExpiredVerificationLink_expired_verification_container__VmMi8 header {
  display: flex;
  justify-content: center;
}

.ExpiredVerificationLink_expired_verification_container__VmMi8 img {
  height: 6rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/expired-verification-link/styles/ExpiredVerificationLink.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,0CAA0C;EAC1C,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".expired_verification_container {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 2rem;\n  background-color: var(--backgroundPrimary);\n  padding: 0 1rem;\n}\n\n.expired_verification_container header {\n  display: flex;\n  justify-content: center;\n}\n\n.expired_verification_container img {\n  height: 6rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expired_verification_container": `ExpiredVerificationLink_expired_verification_container__VmMi8`
};
export default ___CSS_LOADER_EXPORT___;
