import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
} from "react";
import { SESSION_LOGIN_ENDPOINT } from "../api/endpoints";
import { useCustomAxios } from "../api/axios";
import { noop } from "../utils/general";

type UserDataType = {
  user_type: string;
  first_name: string;
  last_name: string;
  email: string;
  created_at: string;
} & (
  | {
      /* retrieved on user login */ origin: "login";
      user_id: string;
    }
  | {
      /* retrieved on session login */ origin: "session";
      _id: { $oid: string };
    }
);

interface AuthContextType {
  isAuthenticated: boolean;
  userData: UserDataType | null;
  getUserId: (data: UserDataType) => string;
  updateUserType: (newUserType: string) => void;
  updateUserName: (firstname: string, lastname: string) => void;
  login: (data: { [key: string]: string }) => void;
  logout: typeof noop;
}

const AuthContext = createContext({} as AuthContextType);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    () => !!localStorage.getItem("isLoggedIn")
  );
  const [userData, setUserData] = useState<UserDataType | null>(null);

  const getUserId = (data: UserDataType) => {
    switch (data.origin) {
      case "login":
        return data.user_id;
      case "session":
        return data._id.$oid;
    }
  };

  const updateUserType = (newUserType: string) => {
    setUserData({ ...userData, user_type: newUserType } as UserDataType);
  };

  const updateUserName = (firstname: string, lastname: string) => {
    setUserData({
      ...userData,
      first_name: firstname,
      last_name: lastname,
    } as UserDataType);
  };

  const login = (data: { [key: string]: string }) => {
    localStorage.setItem("isLoggedIn", "true");
    setIsAuthenticated(true);
    setUserData({
      ...data,
      origin: "login",
    } as UserDataType);
  };

  const logout = () => {
    localStorage.removeItem("isLoggedIn");
    setIsAuthenticated(false);
    setUserData(null);
  };

  const { makeRequest } = useCustomAxios();

  // runs on first render if user logged in previously i.e. session cookie is still valid
  // so no login required in current session but user data is unavailable
  useEffect(() => {
    if (isAuthenticated && !userData) {
      makeRequest(
        [
          {
            url: SESSION_LOGIN_ENDPOINT,
            method: "get",
          },
        ],
        (responseDataArr) => {
          const [responseData] = responseDataArr;
          setUserData({
            ...responseData,
            origin: "session",
          } as UserDataType);
        },
        () => {
          setIsAuthenticated(false);
          localStorage.removeItem("isLoggedIn");
        }
      );
    }
  }, []);

  const contextValue = {
    isAuthenticated,
    userData,
    getUserId,
    updateUserType,
    updateUserName,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
