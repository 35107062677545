// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExpiredPasswordReset_expired_password_reset_container__6k1N2 {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    background-color: var(--backgroundPrimary);
    padding: 0 1rem;
}

.ExpiredPasswordReset_expired_password_reset_container__6k1N2 header {
    display: flex;
    justify-content: center;
}

.ExpiredPasswordReset_expired_password_reset_container__6k1N2 img {
    height: 6rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/expired-password-reset/styles/ExpiredPasswordReset.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,0CAA0C;IAC1C,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".expired_password_reset_container {\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 2rem;\n    background-color: var(--backgroundPrimary);\n    padding: 0 1rem;\n}\n\n.expired_password_reset_container header {\n    display: flex;\n    justify-content: center;\n}\n\n.expired_password_reset_container img {\n    height: 6rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expired_password_reset_container": `ExpiredPasswordReset_expired_password_reset_container__6k1N2`
};
export default ___CSS_LOADER_EXPORT___;
