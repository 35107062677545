// const BASE_URL = "http://127.0.0.1:9115/api"
const BASE_URL = "https://api.lera.cloud/api";

const SIGNUP_ENDPOINT = BASE_URL + "/signup";
const LOGOUT_ENDPOINT = BASE_URL + "/logout";
const LOGIN_ENDPOINT = BASE_URL + "/login";
const SESSION_LOGIN_ENDPOINT = BASE_URL + "/session_login";
const ACTIVATE_ACCOUNT_ENDPOINT = BASE_URL + "/activate_account";
const RETRY_VERIFICATION_ENDPOINT = BASE_URL + "/resend-email";
const FORGOT_PASSWORD_ENDPOINT = BASE_URL + "/forgot_password";
const RESET_PASSWORD_ENDPOINT = BASE_URL + "/reset_password";
const CREATE_KEY_ENDPOINT = BASE_URL + "/create_key";
const UPDATE_KEY_ENDPOINT = BASE_URL + "/update_key";
const GET_USER_KEYS_ENDPOINT = BASE_URL + "/userkeys";
const GET_PLAN_DETAILS = BASE_URL + "/plan_details";
const CREATE_CHECKOUT_SESSION_ENDPOINT = BASE_URL + "/create_checkout_session";
const CHECKOUT_SUCCESS_ENDPOINT = BASE_URL + "/success";
const KEY_AUDITS_ENDPOINT = BASE_URL + "/key_audits";
const TRANSACTION_HISTORY_ENDPOINT = BASE_URL + "/transaction_history";
const GENERATE_TOKEN_ENDPOINT = BASE_URL + "/generate_token";
const UPDATE_USER_INFO_ENDPOINT = BASE_URL + "/update_user";

export {
  LOGIN_ENDPOINT,
  SIGNUP_ENDPOINT,
  LOGOUT_ENDPOINT,
  ACTIVATE_ACCOUNT_ENDPOINT,
  RETRY_VERIFICATION_ENDPOINT,
  FORGOT_PASSWORD_ENDPOINT,
  RESET_PASSWORD_ENDPOINT,
  SESSION_LOGIN_ENDPOINT,
  CREATE_KEY_ENDPOINT,
  UPDATE_KEY_ENDPOINT,
  GET_USER_KEYS_ENDPOINT,
  GET_PLAN_DETAILS,
  CREATE_CHECKOUT_SESSION_ENDPOINT,
  CHECKOUT_SUCCESS_ENDPOINT,
  KEY_AUDITS_ENDPOINT,
  TRANSACTION_HISTORY_ENDPOINT,
  GENERATE_TOKEN_ENDPOINT,
  UPDATE_USER_INFO_ENDPOINT,
};
