import {
  useState,
  useRef,
  useEffect,
  ChangeEvent,
  FormEvent,
  useId,
} from "react";
import { UPDATE_KEY_ENDPOINT } from "../../../../api/endpoints";
import { useCustomAxios } from "../../../../api/axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { TextField } from "../../../../components/TextField";
import { Icon } from "../../../../components/Icon";
import styles from "./styles/EditKeyDetail.module.css";

interface EditKeyDetailProps {
  keyValue: string;
  detailLabel: string;
  initialDetailValue: string | number;
}

export const EditKeyDetail = ({
  keyValue,
  detailLabel,
  initialDetailValue,
}: EditKeyDetailProps) => {
  const id = useId();
  const { isLoading, makeRequest } = useCustomAxios();

  const getCapitalizedDetailLabel = () =>
    detailLabel
      .split("_")
      .map((substr) => substr[0].toUpperCase() + substr.slice(1))
      .join(" ");
  // value for both currently editable key details are of string type
  const [detailValue, setDetailValue] = useState(initialDetailValue as string);
  const [formData, setFormData] = useState({
    [detailLabel]: detailValue,
  });
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setFormData({ [name]: value });
  };
  const inputRef = useRef<HTMLInputElement>(null);
  const editButtonRef = useRef<HTMLButtonElement>(null);

  const [detailEditFinalised, setDetailEditFinalised] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const renderEditForm = () => {
    setDetailEditFinalised(false); // reset
    setShowEditForm(true);
  };
  const cancelDetailUpdate = () => {
    setShowEditForm(false);
    setFormData({ [detailLabel]: detailValue }); // reset to pre-edit value
    setDetailEditFinalised(true);
  };
  const handleDetailUpdate = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowEditForm(false);
    makeRequest(
      [
        {
          url: UPDATE_KEY_ENDPOINT,
          method: "post",
          data: {
            api_key: keyValue,
            ...formData,
          },
        },
      ],
      () => {
        setDetailValue(formData[detailLabel]); // update the UI
        setDetailEditFinalised(true);
      },
      (error) => console.error(error)
    );
  };

  // focus the input when form is opened
  useEffect(() => {
    if (showEditForm) inputRef.current?.focus();
  }, [showEditForm]);

  // focus the edit button after form is closed
  useEffect(() => {
    if (detailEditFinalised) editButtonRef.current?.focus();
  }, [detailEditFinalised]);

  return isLoading ? (
    // TODO: refactor to HTML component
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
      <Typography role="alert">{`Updating ${getCapitalizedDetailLabel()}...`}</Typography>
    </Backdrop>
  ) : showEditForm ? (
    <form noValidate className={styles.edit_form} onSubmit={handleDetailUpdate}>
      <TextField
        id={detailLabel}
        label={getCapitalizedDetailLabel()}
        name={detailLabel}
        value={formData[detailLabel]}
        setValue={handleInputChange}
        stylesClass={styles.text_field}
        ref={inputRef}
      />
      <button type="submit">
        <Icon name="Check" hidden4Sr={false} accessible_name="Save" />
      </button>
      <button type="button" onClick={cancelDetailUpdate}>
        <Icon name="Close" hidden4Sr={false} accessible_name="Cancel" />
      </button>
    </form>
  ) : (
    <div className={styles.edit}>
      <span id={`${id}-${detailLabel}`} aria-hidden>
        {detailValue
          ? `Edit ${getCapitalizedDetailLabel()}: ${
              detailValue[0].toUpperCase() + detailValue.slice(1)
            }`
          : `Edit ${getCapitalizedDetailLabel()}`}
      </span>
      <button
        type="button"
        onClick={renderEditForm}
        aria-labelledby={`${id}-${detailLabel}`}
        ref={editButtonRef}
      >
        <Icon name="Edit" />
      </button>
    </div>
  );
};
